// ** React Imports
import {useEffect, useRef, useState} from 'react'

// ** Third Party Components
import classnames from 'classnames'
import {AtSign, X} from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'

// ** Reactstrap Imports
import {Button, Col, Form, FormFeedback, Input, Label, Row, Spinner} from 'reactstrap'

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import './Customizer.scss';
import {useDispatch, useSelector} from "react-redux";
import {sendContactUsEmail} from "../../../redux/user/user.thunk";
import {selectBrand, selectContactEmailStatus} from "../../../redux/user/user.slice";

const Customizer = ({openCustomizer, handleOpenCustomizer}) => {
    // ** State
    const {t} = useTranslation("login");
    const dispatch = useDispatch();
    const contactEmailStatus = useSelector(selectContactEmailStatus);
    const brand = useSelector(selectBrand);
    const wrapperRef = useRef(null);
    const selectOptions = [
        {value: t("CONTACT_FORM.CONTACT_FORM_FIELD_DEP_COM"), label: t("CONTACT_FORM.CONTACT_FORM_FIELD_DEP_COM")},
        {value: t("CONTACT_FORM.CONTACT_FORM_FIELD_DEP_TECH"), label: t("CONTACT_FORM.CONTACT_FORM_FIELD_DEP_TECH")}
    ];

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                handleOpenCustomizer(false)
                clearErrors();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef])

    useEffect(() => {
        if (!!brand) {
            reset({
                department: selectOptions[0],
                name: brand?.name || "",
                companyName: brand?.displayName || "",
                email: brand?.contact?.email || "",
                phoneNumber: brand?.contact?.phoneNumber || "",
                message: ""
            })
        }
    }, [brand]);

    // ** Form
    const {register, control, formState: {errors}, handleSubmit, clearErrors, reset} = useForm({
        mode: 'onChange',
        defaultValues: {
            department: selectOptions[0],
            name: brand?.name || "",
            companyName: brand?.displayName || "",
            email: brand?.contact?.email || "",
            phoneNumber: brand?.contact?.phoneNumber || "",
            message: ""
        },
        resolver: yupResolver(yup.object().shape({
            name: yup.string().trim().required(t("CONTACT_FORM.INPUT_ERRORS.VALIDATION_ERRORS.NAME_REQUIRED")),
            companyName: yup.string().trim().required(t("CONTACT_FORM.INPUT_ERRORS.VALIDATION_ERRORS.COMPANY_NAME_REQUIRED")),
            email: yup.string().trim().email(t("CONTACT_FORM.INPUT_ERRORS.VALIDATION_ERRORS.EMAIL_INVALID")).required(t("CONTACT_FORM.INPUT_ERRORS.VALIDATION_ERRORS.EMAIL_REQUIRED")),
            phoneNumber: yup.string().required(t("CONTACT_FORM.INPUT_ERRORS.VALIDATION_ERRORS.PHONE_REQUIRED")),
            message: yup.string().trim().required(t("CONTACT_FORM.INPUT_ERRORS.VALIDATION_ERRORS.MESSAGE_REQUIRED")),
        })),
    })

    const onSubmit = async (data) => {
        await dispatch(sendContactUsEmail({...data, dep: data.department?.value || '', company: data.companyName}));
    }

    // ** Toggles Customizer
    const handleToggle = (e) => {
        e.preventDefault()
        handleOpenCustomizer(!openCustomizer)
        clearErrors();
    }

    const showLoader = () => (
        contactEmailStatus === 'loading'
            ? <>
                <Spinner
                    type="grow"
                    size="sm"
                />
                Loading...
            </>
            : t("CONTACT_FORM.CONTACT_FORM_BUTTON")
    )

    return (
        <div ref={wrapperRef} className={classnames('customizer d-none d-md-block', {open: openCustomizer})}>
            <a
                href='/'
                className={classnames('customizer-toggle d-flex align-items-center justify-content-center', {'hidden': openCustomizer})}
                onClick={handleToggle}
            >
                <AtSign size={24} className='spinner'/>
            </a>
            <PerfectScrollbar className='customizer-content' options={{wheelPropagation: false}}>
                <div class="d-flex align-content-center align-items-center flex-column">
                    <div className={'mb-2'} style={{minHeight: '90vh'}}>
                        <h1 className={'pt-5'}
                            style={{
                                fontWeight: 600,
                                color: 'rgb(24, 49, 83)',
                                textAlign: 'center'
                            }}>{t("CONTACT_FORM.CONTACT_FORM_TITLE")}</h1>
                        <p style={{padding: '16px 40px'}}>{t("CONTACT_FORM.CONTACT_FORM_SUB_MSG")}</p>
                        <a href='/' className='customizer-close' onClick={handleToggle}>
                            <X/>
                        </a>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row style={{padding: '0 40px'}} className={'m-0'}>
                                <Col sm='12' className='mb-1'>
                                    <Label className='form-label' for='department'>
                                        {t("CONTACT_FORM.CONTACT_FORM_FIELD_DEP")}
                                    </Label>
                                    <Controller
                                        id='department'
                                        control={control}
                                        name='department'
                                        render={({field}) => (
                                            <Select
                                                defaultValue={selectOptions[0]}
                                                options={selectOptions}
                                                classNamePrefix='select'
                                                className={classnames('react-select', {'is-invalid': errors.department})}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col sm='12' className='mb-1'>
                                    <Label className='form-label' for='name'>
                                        {t("CONTACT_FORM.CONTACT_FORM_FIELD_NAME")}
                                    </Label>
                                    <Controller
                                        id='name'
                                        name='name'
                                        {...register("name")}
                                        control={control}
                                        render={({field}) => (
                                            <Input {...field}
                                                   placeholder={t("CONTACT_FORM.CONTACT_FORM_FIELD_NAME")}
                                                   invalid={errors.name && true}/>
                                        )}
                                    />
                                    {errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
                                </Col>
                                <Col sm='12' className='mb-1'>
                                    <Label className='form-label' for='companyName'>
                                        {t("CONTACT_FORM.CONTACT_FORM_FIELD_COMPANY_NAME")}
                                    </Label>
                                    <Controller
                                        id='companyName'
                                        name='companyName'
                                        defaultValue=''
                                        control={control}
                                        render={({field}) => <Input {...field}
                                                                    placeholder={t("CONTACT_FORM.CONTACT_FORM_FIELD_COMPANY_NAME")}
                                                                    invalid={errors.companyName && true}/>}
                                    />
                                    {errors.companyName && <FormFeedback>{errors.companyName.message}</FormFeedback>}
                                </Col>
                                <Col sm='12' className='mb-1'>
                                    <Label className='form-label' for='email'>
                                        {t("CONTACT_FORM.CONTACT_FORM_FIELD_EMAIL")}
                                    </Label>
                                    <Controller
                                        id='email'
                                        name='email'
                                        defaultValue=''
                                        control={control}
                                        render={({field}) => <Input {...field}
                                                                    placeholder={t("CONTACT_FORM.CONTACT_FORM_FIELD_EMAIL")}
                                                                    invalid={errors.email && true}/>}
                                    />
                                    {errors.email && <FormFeedback>{errors.email.message}</FormFeedback>}
                                </Col>
                                <Col sm='12' className='mb-1'>
                                    <Label className='form-label' for='phoneNumber'>
                                        {t("CONTACT_FORM.CONTACT_FORM_FIELD_PHONE")}
                                    </Label>
                                    <Controller
                                        id='phoneNumber'
                                        name='phoneNumber'
                                        defaultValue=''
                                        control={control}
                                        render={({field}) => <Input {...field}
                                                                    placeholder={t("CONTACT_FORM.CONTACT_FORM_FIELD_PHONE")}
                                                                    invalid={errors.phoneNumber && true}/>}
                                    />
                                    {errors.phoneNumber && <FormFeedback>{errors.phoneNumber.message}</FormFeedback>}
                                </Col>
                                <Col sm='12' className='mb-1'>
                                    <Label className='form-label' for='message'>
                                        {t("CONTACT_FORM.CONTACT_FORM_FIELD_MESSAGE")}
                                    </Label>
                                    <Controller
                                        id='message'
                                        name='message'
                                        defaultValue=''
                                        control={control}
                                        render={({field}) => <Input type='textarea' rows='3' {...field}
                                                                    placeholder={t("CONTACT_FORM.CONTACT_FORM_FIELD_MESSAGE")}
                                                                    invalid={errors.message && true}/>}
                                    />
                                    {errors.message && <FormFeedback>{errors.message.message}</FormFeedback>}
                                </Col>
                                <Col sm='12'>
                                    <div className='d-flex justify-content-end'>
                                        <Button color="primary" type="submit"
                                                disabled={contactEmailStatus === 'loading'}>
                                            {showLoader()}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <p className='mt-1'>© 2020-2022 ShopMyInfluence. All Rights Reserved</p>
                </div>
            </PerfectScrollbar>
        </div>
    )
}

export default Customizer
