import {createSlice} from "@reduxjs/toolkit";
import {getProductPerformances, getProductStats} from "./product.thunk.js";

const initialState = {
    productStatsData: {
        global: {
            productsSold: 0,
            sharedProductsClicksNotSolded: 0,
            sharedProductsClicks: 0
        },
        charts: {
            productDetail: {
                unit: '',
                labels: [],
                datasets: []
            },
            saleWithColorDetail: {
                labels: [],
                datasets: {
                    data: [],
                }
            },
            categoriesPercent: {
                labels: [],
                datasets: {
                    data: [],
                }
            },
            categoriesTotalSales: {
                labels: [],
                datasets: {
                    data: [],
                }
            }
        },
        tablesData: {
            selectedProductTableData: [],
            saledProductTableData: [],
        }
    },
    getProductStatsStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
    getProductPerformancesStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
    errors: {}
};

export const productSlice = createSlice({
    name: "product",
    initialState,
    extraReducers(builder) {
        builder
            .addCase(getProductStats.pending, (state, action) => {
                state.getProductStatsStatus = 'loading';
            })
            .addCase(getProductStats.fulfilled, (state, action) => {
                delete state.errors.stats; //find error in Errors and delete it
                state.productStatsData = {...state.productStatsData, ...action.payload};
                state.getProductStatsStatus = 'succeeded';
            })
            .addCase(getProductStats.rejected, (state, action) => {
                state.errors = {...state.errors, stats: action.payload};
                state.getProductStatsStatus = 'failed';
            })

            .addCase(getProductPerformances.pending, (state, action) => {
                state.getProductPerformancesStatus = 'loading';
            })
            .addCase(getProductPerformances.fulfilled, (state, action) => {
                delete state.errors.performances; //find error in Errors and delete it
                state.productStatsData = {...state.productStatsData, ...action.payload};
                state.getProductPerformancesStatus = 'succeeded';
            })
            .addCase(getProductPerformances.rejected, (state, action) => {
                state.errors = {...state.errors, performances: action.payload};
                state.getProductPerformancesStatus = 'failed';
            })
    }
});

export const selectProductStatsData = (state) => state.product.productStatsData;

export const selectProductStatsStatus = (state) => state.product.getProductStatsStatus;

export const selectProductPerformancesStatus = (state) => state.product.getProductPerformancesStatus;

export const selectProductErrorsStats = (state) => state.product.errors?.stats;

export const selectProductErrorsPerformances = (state) => state.product.errors?.performances;


export default productSlice.reducer;
