import {formatCustomDate} from "../../utility/Utils";
import {format} from "date-fns";

/**
 * mapped Transactions
 * @param values
 * @param startDate
 * @returns {{unit: string, datasets: [{data: unknown[], label: string}], labels: (*|Date)[]}}
 */
export const mappedTransactions = (values, startDate) => ({
    unit: 'month',
    labels: values && Object.keys(values).map((key) => formatCustomDate(startDate, key, 'month')),
    datasets: [
        {
            label: 'Transactions',
            data: values && Object.values(values).map((value) => value),
        },
    ],
});

/**
 * mapped Sales By Date
 * @param values
 * @param startDate
 * @returns {{unit: string, datasets: {data: unknown[], label: string}, labels: (*|Date)[]}}
 */
export const mappedSalesByDate = (values, startDate) => ({
    unit: 'day',
    labels: values && Object.keys(values).map((key) => formatCustomDate(startDate, key, 'day')),
    datasets: {
        data: values && Object.values(values).map((value) => value),
        label: '',
    },
});

/**
 * mapped Sales By Part of Day
 * @param values
 * @returns {{datasets: {data: unknown[]}, labels: string[]}}
 */
export const mappedSalesByPartDay = (values) => ({
    labels: values && Object.keys(values).map((key) => key),
    datasets: {
        data: Object.values(values).map((value) => value),
    },
});

/**
 * mapped Sales By Week Day
 * @param values
 * @returns {{datasets: {data: unknown[], label: string}, labels: string[]}}
 */
export const mappedSalesByWeekDay = (values) => ({
    labels: values && Object.keys(values).map((key) => key),
    datasets: {
        label: 'Turnover',
        data: values && Object.values(values).map((value) => value),
    },
});

/**
 * mapped Sales By Hours of Day
 * @param values
 * @param startDate
 * @returns {{unit: string, datasets: {data: unknown[]}, labels: string[]}}
 */
export const mappedSalesByHoursDay = (values, startDate) => ({
    unit: 'hour',
    labels: values && Object.keys(values).map((key) =>
        format(formatCustomDate(startDate, key, 'hour'), 'yyyy-MM HH:mm'),
    ),
    datasets: {data: values && Object.values(values).map((value) => value)},
});
