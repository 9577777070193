// Endpoints

export const EP_DASHBOARD_STATS = 'dashboard/insights'
export const EP_DASHBOARD_PERFORMANCES = 'dashboard/performances'

export const EP_PERIODICITY_STATS = 'periodicity/insights'
export const EP_PERIODICITY_PERFORMANCES = 'periodicity/performances'

export const EP_CONVERSIONS_STATS = 'conversions/insights'
export const EP_CONVERSIONS_PERFORMANCES = 'conversions/performances'

export const EP_DEVICE_STATS = 'devices/insights'

export const EP_INFLUENCERS_PERFORMANCES = 'influencers/performances'

export const EP_INFLUENCERS_INSIGHTS = 'influencers/insights'

export const EP_INFLUENCERS_PERIOD_SALES = 'influencers/periodSales'

export const EP_PRODUCTS_STATS = 'products/insights'

export const EP_PRODUCTS_PERFORMANCES = 'products/performances'

export const EP_LOCATION_STATS = 'location/insights'

export const EP_LOCATION_SALES = 'location/sales'

export const EP_SEND_CONTACT_US_EMAIL = '/apiv1/dashboardBrand/sendContactUsEmail'
