import {createAsyncThunk} from '@reduxjs/toolkit';
import {EP_CONVERSIONS_PERFORMANCES} from '@api/endPoints.js';
import {getData} from '@api/api.js';
import {getUnixTime} from 'date-fns';
import _ from "lodash";
import {chartArrayMapper, toPercent} from "../../utility/Utils";
import {influencerTableData, socialMediaTableData, subCategTableData} from "./conversion.mapper";

export const getConversionsPerform = createAsyncThunk(
    'conversion/getConversionsPerform',
    async ({startDate, endDate, brand = 0, language = null}, thunkAPI) => {
        try {
            const params = {
                brand,
                start: getUnixTime(startDate),
                end: getUnixTime(endDate),
                timezone: new Date().getTimezoneOffset()
            }

            const {
                Conversion_rate_total: conversionsRate,
                conversions_per_infs: conversionsPerInfs,
                conversions_per_subcateg: conversionsPerSubCateg,
                conversions_per_socialmedias: conversionsPerSocialMedias
            } = await getData(EP_CONVERSIONS_PERFORMANCES, {params});

            const {conversionsStatsData} = thunkAPI.getState().conversion;

            const conversionsPerSubCategOrderBy = conversionsPerSubCateg ? _.orderBy(conversionsPerSubCateg, "sales_nbr", "desc") : [];
            const conversionsPerInfsOrderBy = conversionsPerInfs ? _.orderBy(conversionsPerInfs, "sales_nbr", "desc") : [];
            const conversionsPerSocialMediasOrderBy = conversionsPerSocialMedias ? _.orderBy(conversionsPerSocialMedias, "sales_nbr", "desc") : [];

            return {
                ...conversionsStatsData,
                conversionsRate: toPercent(conversionsRate ? conversionsRate * 100 : 0),
                subCateg: {
                    chartData: chartArrayMapper(conversionsPerSubCategOrderBy.slice(0, 6), `${'text-' + language}`, 'sales_nbr'),
                    tableData: subCategTableData(conversionsPerSubCateg, language)
                },
                influencer: {
                    chartData: chartArrayMapper(conversionsPerInfsOrderBy.slice(0, 6), 'influencer', 'sales_nbr'),
                    tableData: influencerTableData(conversionsPerInfs),
                },
                socialMedia: {
                    chartData: chartArrayMapper(conversionsPerSocialMediasOrderBy.filter((s) => s.socialMedia).slice(0, 6), 'socialMedia', 'sales_nbr'),
                    tableData: socialMediaTableData(conversionsPerSocialMedias)
                }
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.message);
        }
    },
)
