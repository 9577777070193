import {createSlice} from '@reduxjs/toolkit'
import {getDeviceStats} from './device.thunk.js'
import {getLabels, groupBy} from '../../utility/Utils.js'
import {selectDashboardErrorsGlobal} from "../dashboard/dashboard.slice";

const initialState = {
    deviceStatsData: {
        sectionOne: {
            transPerOs: {
                unit: '',
                labels: [],
                datasets: [],
            },
            transPerDevice: {
                unit: '',
                labels: [],
                datasets: [],
            },
        },
        sectionTwo: {
            transPercentPerOs: {
                labels: [],
                datasets: {
                    data: [],
                },
            },
            transPercentPerDevice: {
                labels: [],
                datasets: {
                    data: [],
                },
            },
        },
    },
    getDeviceStatsStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
    errors: {},
}

export const deviceSlice = createSlice({
    name: 'device',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(getDeviceStats.pending, (state, action) => {
                state.getDeviceStatsStatus = 'loading';
            })
            .addCase(getDeviceStats.fulfilled, (state, action) => {
                delete state.errors.stats; //find error in Errors and delete it
                state.deviceStatsData = {...state.deviceStatsData, ...action.payload};
                state.getDeviceStatsStatus = 'succeeded';
            })
            .addCase(getDeviceStats.rejected, (state, action) => {
                state.errors = {...state.errors, stats: action.payload}
                state.getDeviceStatsStatus = 'failed';
            })
    }
})

export const selectDeviceStatsData = (state) => state.device.deviceStatsData;

export const selectDeviceStatsStatus = (state) => state.device.getDeviceStatsStatus;

export const selectDeviceErrorsStats = (state) => state.device.errors?.stats;

export default deviceSlice.reducer
