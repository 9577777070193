// ** Icons Import
import {Heart} from 'react-feather'
import {useTranslation} from "react-i18next";

const Footer = ({handleOpenCustomizer}) => {
    const {t} = useTranslation("translation");

    const handleToggle = (e) => {
        e.preventDefault()
        handleOpenCustomizer(true)
    }

    return (
        <p className='clearfix mb-0'>
          <span className='float-md-start d-block d-md-inline-block mt-25'>
            COPYRIGHT © {new Date().getFullYear()}{' '}
              <a href='#' target='_blank' rel='noopener noreferrer'>SMI Insight</a>
            <span className='d-none d-sm-inline-block'>
                {t("FOOTER_TITLE")} - <a href='/' onClick={handleToggle}>{t("CONTACT_US")}</a>
            </span>
          </span>
            <span className='float-md-end d-none d-md-block'>{t("FOOTER_SUB_TITLE")}<Heart size={14}/></span>
        </p>
    )
}

export default Footer
