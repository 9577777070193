import axios from "axios";

// TODO: get Token from sessionStorage
const env = process.env.NODE_ENV;
const userToken = localStorage.getItem("userToken") ? localStorage.getItem("userToken") : null;
const client = axios.create({
    baseURL:
        env === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD,
    headers: {
        Authorization: `Bearer ${userToken}`,
    },
});
const clientSmi = axios.create({
    baseURL:
        env === "development" ? process.env.REACT_APP_BASE_URL_DEV_SMI : process.env.REACT_APP_BASE_URL_PROD_SMI,
    headers: {
        Authorization: `Bearer ${userToken}`,
    },
});

export const getData = async (endPoint, {params, ...rest}) => {
    try {
        const response = await client.get(endPoint, {params, ...rest});
        return response.data;
    } catch (err) {
        throw err;
        // return err;
    }
};

export const postData = async (endPoint, {data, ...rest}) => {
    try {
        const response = await client.get(endPoint, {data, ...rest});
        return response.data;
    } catch (err) {
        return err;
    }
};

export const postDataSmi = async (endPoint, {data, ...rest}) => {
    try {
        const response = await clientSmi.post(endPoint, {data, ...rest});
        return response.data;
    } catch (err) {
        return err;
    }
};
