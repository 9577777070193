import {formatCustomDate} from "../../utility/Utils";
import _ from "lodash";

export const salesChart = (values, unit, startDate, influencers) => {
    if (!values) return {unit, labels: [], datasets: []};

    // FIXME: data should be treated in backend
    const tmpSalesGroupedByHour = _.groupBy(values, unit);
    const tmpSalesGroupedByLabel = _.groupBy(values, 'influencer');
    const labels = getLabels(Object.keys(tmpSalesGroupedByHour), unit, values, startDate);

    const datasets = Object.entries(tmpSalesGroupedByLabel).map(([key1, value]) => ({
        label: influencers?.find((item) => item.uid === key1)?.influencer ?? '',
        data: Object.entries(tmpSalesGroupedByHour).map(([key2, value2]) =>
            value2.find(item => item.influencer === key1)?.total_sales ?? null
        ),
    }));

    return {unit, labels, datasets};
}

const getLabels = (list, unit, globalList = [], startDate) => {
    return list.map((item) => {
            return item === 0 ? startDate : formatCustomDate(startDate, item, unit, globalList.find((item2) => item2[unit] === item)?.year)
        }
    );
}
