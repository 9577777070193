import {getData} from "../../Api/api.js";
import {EP_DASHBOARD_PERFORMANCES, EP_DASHBOARD_STATS} from "../../Api/endPoints";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {getUnixTime} from "date-fns";
import {chartArrayMapper, compactNumber, formatCurrencyCompactEUR} from "../../utility/Utils";
import {mappedCountries, mappedProducts, mappedSalesPartDay} from "./dashboard.mapper";

export const getDashboardStats = createAsyncThunk(
    "dashboard/getDashboardStats",
    async ({startDate, endDate, brand = 0, language = null}, thunkAPI) => {
        try {
            const params = {
                brand,
                start: getUnixTime(startDate),
                end: getUnixTime(endDate),
                timezone: new Date().getTimezoneOffset()
            };

            const {
                sales_per_influencers: salesPerInfluencer,
                sales_per_country: salesPerCountry,
                sales_per_categories: salesPerCategories,
                sales_per_day: salesPerDay,
                sales_per_day_period: salesPerDayPeriod,
                sales_per_os: salesPerOs,
                sales_per_colors: salesPerColors,
                sales_per_products: salesPerProducts
            } = await getData(EP_DASHBOARD_STATS, {params});

            const {dashStatsData} = thunkAPI.getState().dashboard;

            return {
                bestOf: {
                    ...dashStatsData?.bestOf,
                    os: salesPerOs && salesPerOs[0] ? salesPerOs[0]?.os : null,
                    color: salesPerColors ? salesPerColors[0] : undefined,
                    product: salesPerProducts?.[0] ?? null,
                    day: salesPerOs && salesPerOs[0] ? salesPerDay.sort((a, b) => b.turnover - a.turnover)[0]?.day_of_week : null,
                    categ: salesPerCategories ? salesPerCategories[0][`${'text-' + language}`] : "",
                    dayPart: salesPerDayPeriod && Object.entries(salesPerDayPeriod).reduce((max, curr) => curr[1] > max[1] ? curr : max)[0],
                    influencer: salesPerInfluencer?.[0] ?? null,
                },
                charts: {
                    salesPartDay: mappedSalesPartDay(salesPerDayPeriod),
                    os: chartArrayMapper(salesPerOs, 'os', 'turnover', '', ''),
                    categ: chartArrayMapper(salesPerCategories, 'text-fr', 'turnover'),
                    influencers: chartArrayMapper(salesPerInfluencer, 'name', 'turnover', 'img'),
                    products: mappedProducts(salesPerProducts),
                    countries: mappedCountries(salesPerCountry),
                    campaignSales: {labels: [], datasets: {data: []}}
                },
            };
        } catch (error) {
            if (!error.response) {
                throw error
            }
            // return thunkAPI.rejectWithValue("MSG_ERROR_WRONG");
            return thunkAPI.rejectWithValue(error?.message);
        }
    }
);

export const getDashboardGlobal = createAsyncThunk(
    "dashboard/getDashboardGlobal",
    async ({startDate, endDate, brand = 0}, thunkAPI) => {
        try {
            const params = {
                brand,
                start: getUnixTime(startDate),
                end: getUnixTime(endDate),
                timezone: new Date().getTimezoneOffset()
            };

            const {
                best_social_media: bestSocialMedia,
                turnover: _turnover,
                transactions: _transactions,
                average_cart,
                active_influencers_nbr,
                clicks_nbr
            } = await getData(EP_DASHBOARD_PERFORMANCES, {params});

            const {dashStatsData} = thunkAPI.getState().dashboard;

            return {
                global: {
                    turnover: _turnover ? formatCurrencyCompactEUR(_turnover) : 0,
                    transactions: _transactions ? compactNumber(_transactions) : 0,
                    average_cart: average_cart ? formatCurrencyCompactEUR(average_cart) : 0,
                    activeInfs: active_influencers_nbr ? compactNumber(active_influencers_nbr) : 0,
                    clicks: clicks_nbr ? compactNumber(clicks_nbr) : 0,
                    rateConversionData: _turnover && clicks_nbr ? parseFloat((_transactions / clicks_nbr) * 100).toFixed(2) : ''
                },
                bestOf: {
                    ...dashStatsData?.bestOf,
                    socialMedia: bestSocialMedia?.[0]?.name || null,
                },
            };
        } catch (error) {
            // return thunkAPI.rejectWithValue("MSG_ERROR_WRONG");
            return thunkAPI.rejectWithValue(error?.message);
        }
    }
);
