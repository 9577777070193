// ** Redux Imports
import { configureStore } from '@reduxjs/toolkit'

// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import dashboard from './dashboard'
import periodicity from './periodicity'
import conversion from './conversion'
import device from './device'
import datePicker from './datePicker'
import user from './user'
import influencer from './influencer'
import product from './product'
import campaigns from './campaigns'
import localizations from './localizations'

const rootReducer = { navbar, layout, dashboard, periodicity, conversion, device, datePicker, user, influencer, product, campaigns, localizations }
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    })
  },
})

export { store }
