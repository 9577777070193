import {formatNumberChart, getLabels, groupBy} from "../../utility/Utils";

export const transChart = (values, unit, startDate, key) => {
    if (!values) return {unit, labels: [], datasets: []};

    // FIXME: data should be treated in backend
    const tmpDevicePerOsGroupedByHour = groupBy(values, unit);
    const tmpDevicePerOsGroupedByLabel = groupBy(values, key);
    const labels = getLabels(Object.keys(tmpDevicePerOsGroupedByHour), startDate, unit, values);

    const datasets = Object.entries(tmpDevicePerOsGroupedByLabel).map(([key1, value]) => ({
        label: key1,
        data: Object.entries(tmpDevicePerOsGroupedByHour).map(([key2, value2]) =>
            value2.find(item => item[key] === key1)?.count_sale ?? null
        ),
    }));

    return {unit, labels, datasets};
};

export const transPercentPerDeviceChart = (values) => {
    return {
        labels: values ? values.map((item) => item.device + ' \n ' + formatNumberChart(item.percent, 'percentage')) : [],
        datasets: {data: values ? values.map((item) => item.percent) : []},
    }
}
