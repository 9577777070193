import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {selectUser} from "../../../redux/user/user.slice.js";
import {getFbBrand, login, logout, setCurrentUser} from "../../../redux/user/user.thunk.js";
import {getHomeRouteForLoggedInUser} from "../../../utility/Utils.js";
import {auth} from "./firebase.js";

export const useAuth = () => {
    const {user, isLoadingUser} = useSelector(selectUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {hash, pathname} = useLocation();

    /* State hooks */
    useEffect(() => {
        if (!user) {
            auth.onAuthStateChanged((userData) => onChange(userData));
        }

        if (hash && hash.length > 50) {
            loginUser({token: hash});
        }
    }, []);

    const onChange = async (userData) => {
        if (userData) {
            const offerId = hash ? hash.slice(1).split("__")[0] : null;
            offerId && localStorage.setItem("insOId", offerId);
            localStorage.setItem("userToken", userData.multiFactor.user.accessToken);
            userData.multiFactor.user
                .getIdTokenResult()
                .then((idTokenResult) => {
                    let offerId = idTokenResult?.claims?.brandOfferId || null;
                    if (offerId) {
                        //  offerId && localStorage.setItem("insOId", offerId);
                        dispatch(getFbBrand(idTokenResult.claims.brandOfferId))
                            .then((brand) => {
                                dispatch(setCurrentUser({
                                    ...userData.multiFactor.user,
                                    brand: brand.payload,
                                    role: "brand"
                                }));
                                navigate(getHomeRouteForLoggedInUser("brand"));
                            });
                    } else {
                        dispatch(setCurrentUser({
                            ...userData.multiFactor.user,
                            brand: {offerId: localStorage.getItem("insOId")},
                            role: "admin",
                        }));
                        navigate(getHomeRouteForLoggedInUser("admin", pathname));
                    }
                })
                .catch((err) => console.log(err));
        } else {
            // if no user SignOut
            localStorage.removeItem("userToken");
            localStorage.removeItem("insOId");
            dispatch(setCurrentUser(null));
            auth.signOut();
        }
    };

    const logoutUser = () => {
        dispatch(logout());
    };

    const loginUser = async (data) => {
        return new Promise((resolve, reject) => {
            return dispatch(login(data))
                .then((_userData) =>
                    !_userData?.error
                        // ?  resolve(_userData)
                        // ? (hash && hash.length > 50) ? resolve(navigate(getHomeRouteForLoggedInUser("brand"))) : resolve(false)
                        ? resolve(_userData)
                        : reject(_userData?.error)
                )
                .catch((err) => {
                    reject(false)
                    // resolve(false);
                });
        });
    };

    const getClaimsRole = async (userData) => {
        const claims = await userData.getIdTokenResult().then((idTokenResult) => {
            return idTokenResult.claims;
        });
        const role = claims?.admin ? "admin" : claims?.brand ? "brand" : null;

        return role;
    };

    const getIdToken = async () => {
        return await user.getIdTokenResult().then((idTokenRes) => idTokenRes);
    };

    return {
        user,
        logoutUser,
        loginUser,
        isLoadingUser,
        getIdToken
    };
};
