// ** React Imports
import {Suspense} from "react";
import {Navigate} from "react-router-dom";
import {RingLoader} from "react-spinners";

// ** Context Imports

import {useAuth} from "../../auth/firebase/useAuth.js";

const PrivateRoute = ({children, route}) => {
        const {user, isLoadingUser, isLoadingBrand} = useAuth();

        // ** Hooks & Vars
        // const ability = useContext(AbilityContext)

        if (route) {
            let action = null;
            let resource = null;
            let restrictedRoute = false;

            if (route.meta) {
                action = route.meta.action;
                resource = route.meta.resource;
                restrictedRoute = route.meta.restricted;
            }

            if (!isLoadingUser && !isLoadingBrand && !user) {
                return <Navigate to="/login"/>;
            }

            //   if (!isLoadingUser && !isLoadingBrand) {
            //    console.log("RUNNING");
            //    return user ? <Navigate to={getHomeRouteForLoggedInUser("brand")} /> : <Navigate to="/login" />;
            //   }

            // FIXME: get Claims and redirect user with attribute restrictedRoute

            // if (user && restrictedRoute && user.role === "client") {
            //   return <Navigate to="/access-control" />
            // }
            // if (user && !ability.can(action || "read", resource)) {
            //   return <Navigate to="/misc/not-authorized" replace />
            // }
        }

        const fallback = () => (
            <div
                style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexGrow: "1",
                }}
            >
                <RingLoader color="#022957"/>
            </div>
        );

        if (user !== undefined || null) {
            return (
                <Suspense fallback={null}>
                    {children}
                </Suspense>
            );
        }
    }
;

export default PrivateRoute;
