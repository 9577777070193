import {createAsyncThunk} from "@reduxjs/toolkit";
import {auth, database} from "../../@core/auth/firebase/firebase.js";
import {getData, postDataSmi} from "../../Api/api";
import {EP_DASHBOARD_PERFORMANCES, EP_SEND_CONTACT_US_EMAIL} from "../../Api/endPoints";
import {getUnixTime} from "date-fns";

export const login = createAsyncThunk("user/login", async (values, thunkAPI) => {
    const connectionMethod = values.checked ? "local" : "session";
    let signInPromise = {};

    const token = values?.token || null;
    try {
        if (token) {
            const customToken = token.slice(1).split("__")[1];
            // [START auth_sign_in_custom]
            let persistancePromise = await auth.setPersistence(connectionMethod);
            signInPromise = auth
                .signInWithCustomToken(customToken)
                .then((userCredential) => {
                    // Signed in
                    return userCredential.user;

                    // ...
                })
                .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    console.log(errorMessage);
                    // throw new Error(error.code);
                    return thunkAPI.rejectWithValue("MSG_ERROR_WRONG");
                });
            // [END auth_sign_in_custom]
        } else {
            signInPromise = await auth
                .signInWithEmailAndPassword(values.email, values.password)
                .then((userCredential) => {
                    // Signed in
                    return userCredential.user;
                    // ...
                })
                .catch(err => {
                    throw new Error(err.code)
                });
        }

        const claims = await signInPromise?.getIdTokenResult().then((idTokenResult) => {
            return idTokenResult.claims;
        });

        const role = claims ? (claims?.admin ? "admin" : claims?.brand ? "brand" : "") : null;
        if (role && (role === "admin" || role === "brand")) {
            return {...signInPromise.multiFactor.user, ...claims, role};
        } else {
            auth.signOut();
            return thunkAPI.rejectWithValue("MSG_ERROR_WRONG");
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const logout = createAsyncThunk("user/logout", async (_, thunkAPI) => {
    try {
        auth.signOut();
        return;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const getFbBrand = createAsyncThunk("user/brands", async (offerId, thunkAPI) => {
    try {
        let brand = await database
            .ref("brands")
            .orderByChild("offerId")
            .equalTo(offerId)
            .once("value")
            .then((snap) => {
                return snap.exists() ? Object.values(snap.val())[0] : null;
            })
            .catch((err) => console.log("err", err.message));
        return brand;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const setCurrentUser = createAsyncThunk("user/setCurrentUser", async (data, thunkAPI) => {
    try {
        // if (data?.role !== ('admin' || 'brand')) {
        //   auth.signOut()
        //   return thunkAPI.rejectWithValue('MSG_ERROR_WRONG')
        // }

        return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const sendContactUsEmail = createAsyncThunk(
    "user/sendContactUsEmail",
    async ({message, email, name, company, phoneNumber, dep}, thunkAPI) => {
        try {
            const params = {message, email, name, company, phoneNumber, dep};

            return await postDataSmi(EP_SEND_CONTACT_US_EMAIL, {...params});
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    });
