import _ from "lodash";
import {formatCustomDate} from "../../utility/Utils";

export const continentsChart = (values, startDate) => {
    // FIXME: data should be treated in backend
    const unitContinent = values?.[0]?.type ?? '';
    const tmpContinentSalesPerPeriodGroupedByHour = values?.length ? _.groupBy(values, 'period') : [];
    const tmpContinentSalesPerPeriodGroupedByLabel = values?.length ? _.groupBy(values, 'continent') : [];
    const formattedLabelsContinent = values ? getLabels(Object.keys(tmpContinentSalesPerPeriodGroupedByHour), startDate, unitContinent, values) : []

    return {
        unit: unitContinent,
        labels: formattedLabelsContinent,
        datasets: Object.entries(tmpContinentSalesPerPeriodGroupedByLabel)
            .map(([key1, value]) => {
                return {
                    label: key1,
                    data: Object.entries(tmpContinentSalesPerPeriodGroupedByHour)
                        .map(([key2, value2]) => value2.find((item) => item.continent === key1)?.total ?? 0)
                }
            })
    }
}

const getLabels = (list, startDate, unit, globalList) => {
    return list.map((item, index) => {
        const year = globalList.filter(item2 => item2.period == item).shift()?.year;
        return item === 0 ? startDate : formatCustomDate(startDate, item, unit, year)
    });
}
