// ** React Imports
import {Fragment, lazy} from "react";
import {Navigate, useLocation} from "react-router-dom";
// ** Layouts
import BlankLayout from "@layouts/BlankLayout";
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper";
import HorizontalLayout from "@src/layouts/HorizontalLayout";
import VerticalLayout from "@src/layouts/VerticalLayout";

// ** Route Components
import PrivateRoute from "@components/routes/PrivateRoute";
import PublicRoute from "@components/routes/PublicRoute";

// ** Utils
import {isObjEmpty} from "@utils";

const getLayout = {
    blank: <BlankLayout/>,
    vertical: <VerticalLayout/>,
    horizontal: <HorizontalLayout/>,
};

// ** Document title
const TemplateTitle = "%s - SMI Insight";

// ** Default Route
const DefaultRoute = "/dashboard";

const Dashboard = lazy(() => import("../../views/Dashboard/Dashboard"));
const PeriodicityDashboard = lazy(() => import("../../views/Periodicity/PeriodicityDashboard"));
const Conversion = lazy(() => import("../../views/Conversion/Conversion"));
const Device = lazy(() => import("../../views/Device/Device"));
const Country = lazy(() => import("../../views/Country/Country"));
const Gifting = lazy(() => import("../../views/Gifting/Gifting"));
const Influencers = lazy(() => import("../../views/Influencers/Influencers"));
const Product = lazy(() => import("../../views/Product/Product"));
const Localization = lazy(() => import("../../views/Localization/Localizations"));
const StatsDashboard = lazy(() => import("../../views/StatsDashboard/StatsDashboard"));
const Login = lazy(() => import("../../views/Login/Login"));
// const Register = lazy(() => import("../../views/Register"))
// const ForgotPassword = lazy(() => import("../../views/ForgotPassword"))
const Error = lazy(() => import("../../views/Error"));

// FIXME: meta:{restricted: true}


// ** Merge Routes
const Routes = [
    {
        path: "/",
        index: true,
        element: <Navigate replace to={DefaultRoute}/>,
    },
    {
        path: "/dashboard",
        element: <Dashboard/>,
    },
    {
        path: "periodicity-dashboard",
        element: <PeriodicityDashboard/>,
    },
    {
        path: "conversion",
        element: <Conversion/>,
    },
    {
        path: "device",
        element: <Device/>,
    },
    // Select brand restricted route Admin
    // {
    // path: '/select-brand',
    //element: <SelectBrand />,
    // meta:{
    //  restricted: true,
    // }
    // {
    //   path: 'country',
    //   element: <Country />,
    // },
    // {
    //   path: 'gifting',
    //   element: <Gifting />,
    // },
    {
        path: 'influencers',
        element: <Influencers/>,
    },
    {
        path: 'products',
        element: <Product/>,
    },
    {
        path: 'localizations',
        element: <Localization/>,
    },
    {
        path: "category",
        element: <StatsDashboard/>,
    },
    {
        path: "/login",
        element: <Login/>,
        meta: {
            layout: "blank",
        },
    },

    {
        path: "*",
        element: <Error/>,
        meta: {
            layout: "blank",
        },
    },
];

const getRouteMeta = (route) => {
    if (isObjEmpty(route.element.props)) {
        if (route.meta) {
            return {routeMeta: route.meta};
        } else {
            return {};
        }
    }
};

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
    const LayoutRoutes = [];

    if (Routes) {
        Routes.filter((route) => {
            let isBlank = false;
            // ** Checks if Route layout or Default layout matches current layout
            if (
                (route.meta && route.meta.layout && route.meta.layout === layout) ||
                ((route.meta === undefined || route.meta.layout === undefined) && defaultLayout === layout)
            ) {
                // ** Check for public or private route
                if (route.meta) {
                    route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false);
                }
                if (route.element) {
                    const RouteTag = isBlank ? PublicRoute : PrivateRoute;
                    const Wrapper =
                        isObjEmpty(route.element.props) && isBlank === false ? LayoutWrapper : Fragment;

                    route.element = (
                        <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
                            <RouteTag route={route}>{route.element}</RouteTag>
                        </Wrapper>
                    );
                }

                // Push route to LayoutRoutes
                LayoutRoutes.push(route);
            }
            return LayoutRoutes;
        });
    }
    return LayoutRoutes;
};

const getRoutes = (layout) => {
    const defaultLayout = layout || "vertical";
    const layouts = ["vertical", "horizontal", "blank"];

    const AllRoutes = [];

    layouts.forEach((layoutItem) => {
        const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout);
        AllRoutes.push({
            path: "/",
            element: getLayout[layoutItem] || getLayout[defaultLayout],
            children: LayoutRoutes,
        });
    });
    return AllRoutes;
};

export {DefaultRoute, TemplateTitle, Routes, getRoutes};
