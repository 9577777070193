// ** Logo
import logo from "@src/assets/images/logo/logo.png";
import RingLoader from "react-spinners/RingLoader";
import ComponentSpinner from "./Loading-spinner";

const SpinnerComponent = () => {
 return (
  <div className="fallback-spinner app-loader">
   <img className="fallback-logo" src={logo} alt="logo" />
   <div className="loading">
       <div>
           <ComponentSpinner/>
           {/*<RingLoader color="#022957"/>*/}
       </div>
   </div>
  </div>
 );
};

export default SpinnerComponent;
