import {createAsyncThunk} from '@reduxjs/toolkit'
import {chartArrayMapper, getUnixTimestampUTC} from '../../utility/Utils.js'
import {transChart, transPercentPerDeviceChart} from "./device.mapper";
import {EP_DEVICE_STATS} from "../../Api/endPoints";
import {getData} from "../../Api/api";

export const getDeviceStats = createAsyncThunk(
    'device/getDeviceStats',
    async ({startDate, endDate, brand = 0}, thunkAPI) => {
        try {
            const params = {
                brand,
                start: getUnixTimestampUTC(startDate),
                end: getUnixTimestampUTC(endDate),
                timezone: new Date().getTimezoneOffset()
            }

            const {
                sales_per_os: salesPerOs,
                sales_per_device: salesPerDevice,
                percent_sales_per_device: percentSalesPerDevice,
                percent_sales_per_os: percentSalesPerOs,
            } = await getData(EP_DEVICE_STATS, {params})

            const ordredPercentSalesPerOs = percentSalesPerOs.sort((a, b) => b.percent - a.percent);

            return {
                sectionOne: {
                    transPerOs: transChart(salesPerOs, salesPerOs ? salesPerOs[0].type : '', startDate, 'os'),
                    transPerDevice: transChart(salesPerDevice, salesPerDevice?.[0]?.type || '', startDate, 'device'),
                },
                sectionTwo: {
                    transPercentPerOs: chartArrayMapper(ordredPercentSalesPerOs, 'os', 'percent'),
                    transPercentPerDevice: transPercentPerDeviceChart(percentSalesPerDevice)
                },
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.message);
        }
    },
)
