import {formatCustomDate} from "../../utility/Utils";

/**
 * mapped Product Detail
 * @param {Array}values
 * @param {String}unit
 * @param startDate
 * @returns {{unit, datasets: [{data: (*|*[]), label: string}], labels: (*|*[])}}
 */
export const mappedProductDetail = (values, unit, startDate) => {
    return {
        unit,
        labels: values?.map(item => formatCustomDate(startDate, item[unit], unit, item?.year)) || [],
        datasets: [{label: '', data: values?.map((item) => item.articles_count) || []}],
    }
}
