// ** React Imports
import { Suspense } from 'react'
import { Navigate } from 'react-router-dom'

// ** Utils

import { getHomeRouteForLoggedInUser } from '../../../utility/Utils.js'
import { useAuth } from '../../auth/firebase/useAuth.js'

const PublicRoute = ({ children, route }) => {
  const { user: userData } = useAuth()

  if (route) {
    const user = userData
    const restrictedRoute = route.meta && route.meta.restricted

    if (user && restrictedRoute) {
      return <Navigate to={getHomeRouteForLoggedInUser(user.role)} />
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PublicRoute
