import {createAsyncThunk} from '@reduxjs/toolkit';
import {getUnixTime} from 'date-fns';
import {EP_PERIODICITY_PERFORMANCES, EP_PERIODICITY_STATS} from "../../Api/endPoints";
import {getData} from "../../Api/api";
import {
    mappedSalesByDate,
    mappedSalesByHoursDay,
    mappedSalesByPartDay,
    mappedSalesByWeekDay,
    mappedTransactions
} from "./periodicity.mapper";

export const getPeriodicityStats = createAsyncThunk(
    'periodicity/getPeriodicityStats',
    async ({startDate, endDate, brand = 0}, thunkAPI) => {
        const params = {
            brand,
            start: getUnixTime(startDate),
            end: getUnixTime(endDate),
            timezone: new Date().getTimezoneOffset()
        }

        try {
            const {
                turnover_by_months: {
                    selected_period: turnoverByMonths,
                    last_symetric_period: prevTurnoverByMonths,
                },
                turnover_by_days: {selected_period: turnoverByDays},
                turnover_by_day_period: {selected_period: turnoverByDayPeriod},
                turnover_by_week_days: {selected_period: turnoverByWeekPeriod},
                turnover_by_hours: {selected_period: turnoverByHours},
            } = await getData(EP_PERIODICITY_STATS, {params});

            const {periodStatsData} = thunkAPI.getState().periodicity;

            return {
                salesGrowth: {
                    ...periodStatsData?.salesGrowth,
                    transactions: mappedTransactions(turnoverByMonths, startDate),
                },
                salesGranularity: {
                    salesByDate: mappedSalesByDate(turnoverByDays, startDate),
                    salesByPartDay: mappedSalesByPartDay(turnoverByDayPeriod),
                    salesByWeekDay: mappedSalesByWeekDay(turnoverByWeekPeriod),
                    salesByHoursDay: mappedSalesByHoursDay(turnoverByHours, startDate)
                },
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.message);
        }
    },
)

export const getPeriodicityPerformances = createAsyncThunk(
    'periodicity/getPeriodicityPerforms',
    async ({startDate, endDate, brand = 0}, thunkAPI) => {
        const growthMsg = 'growth';
        const decreaseMsg = 'decrease';
        try {
            const params = {
                brand,
                start: getUnixTime(startDate),
                end: getUnixTime(endDate),
                timezone: new Date().getTimezoneOffset()
            }
            const result = await getData(EP_PERIODICITY_PERFORMANCES, {params});

            const {
                last_symetric_period_sales: lastSymmetricPeriodSales,
                selected_period_sales: selectedPeriodSales,
            } = result;

            const tmpSales = (
                ((selectedPeriodSales - lastSymmetricPeriodSales) / selectedPeriodSales) *
                100
            ).toFixed(2)

            return {
                salesGrowth: {
                    ...thunkAPI.getState().periodicity?.periodStatsData?.salesGrowth,
                    sales: {
                        value: tmpSales > 0 ? tmpSales : tmpSales * -1,
                        label: tmpSales > 0 ? growthMsg : decreaseMsg,
                    },
                },
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.message);
        }
    },
)
