import _ from "lodash";
import {toPercent} from "../../utility/Utils";

export const subCategTableData = (values, language) => {
    return _.orderBy(
        values?.map((item) => {
            return {
                name: item[`${'text-' + language}`],
                transactions: item.sales_nbr,
                clicks: item.clics,
                rate: toPercent(item.conversions_rate ? item.conversions_rate * 100 : 0)
            };
        }),
        "transactions",
        "desc"
    )
}

export const influencerTableData = (values) => {
    return values ? _.orderBy(
        values.map((item) => {
            return {
                name: item.influencer,
                transactions: item.sales_nbr,
                clicks: item.clics,
                rate: toPercent(item.conversions_rate ? item.conversions_rate * 100 : 0)
            }
        }),
        'transactions',
        'desc',
    ) : [];
}

export const socialMediaTableData = (values) => {
    return _.orderBy(
        values ? values
            .filter((s) => s.socialMedia)
            .map((item) => {
                return {
                    name: item.socialMedia,
                    transactions: item.sales_nbr,
                    clicks: item.clics,
                    rate: toPercent(item.conversions_rate ? item.conversions_rate * 100 : 0)
                };
            }) : [],
        "transactions",
        "desc"
    )
}
