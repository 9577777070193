import {formatCurrencyCompactEUR} from "../../utility/Utils";

/**
 * mapped Products
 * @param {Array}values
 * @returns {{datasets: {images: *[], data: *[]}, labels: *[]}}
 */
export const mappedProducts = (values) => {
    const labels = [];
    const data = [];
    const images = [];
    for (const value of values || []) {
        labels.push("");
        data.push(value.turnover);
        images.push({url: value?.url, img: value?.img});
    }

    return {labels, datasets: {data, images}}
}

/**
 * mapped Sales PartDay
 * @param {Array}values
 * @returns {{datasets: {data: *[], label: string}, labels: *[]}}
 */
export const mappedSalesPartDay = (values) => {
    const labels = [];
    const data = [];
    values = Object.entries(values)?.sort((a, b) => b[1] - a[1]);
    Object.values(values).forEach((value, index) => {
        labels.push(value[0]);
        data.push(value[1]);
    })

    return {labels, datasets: {label: "", data}}
}

/**
 * mapped Countries
 * @param {Array}values
 * @returns {{datasets: {data: *[], tooltips: *[]}, labels: *[]}}
 */
export const mappedCountries = (values) => {
    const labels = [];
    const data = [];
    const tooltips = [];
    for (const value of values || []) {
        labels.push({countryCode: value.code, value: formatCurrencyCompactEUR(value.turnover)});
        data.push(value.turnover);
        tooltips.push(`${value.code}: ${formatCurrencyCompactEUR(value.turnover)}`);
    }

    return {labels, datasets: {data, tooltips}}
}
