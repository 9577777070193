import {
  Calendar,
  Home,
  CreditCard,
  Smartphone,
  Map,
  Gift,
  Users,
  Box,
  FileMinus,
  FolderPlus,
  Bookmark, ShoppingBag
} from "react-feather";

export default [
  // {
  //   id: "home",
  //   title: "Home",
  //   icon: <Home size={20} />,
  //   navLink: "/home"
  // },
  {
    id: 'dashboard',
    title: { en: 'Dashboard', fr: 'Tableau de bord ' },
    icon: <Home size={20} />,
    navLink: '/dashboard',
  },
  {
    id: 'periodicity-dashboard',
    title: { en: 'Periodicity', fr: 'Périodicité' },
    icon: <Calendar size={20} />,
    navLink: '/periodicity-dashboard',
  },
  {
    id: 'conversion',
    title: { en: 'Conversion', fr: 'Conversion' },
    icon: <CreditCard size={20} />,
    navLink: '/conversion',
  },
  {
    id: 'device',
    title: { en: 'Device', fr: 'Appareil' },
    icon: <Smartphone size={20} />,
    navLink: '/device',
  },
  // FixMe: navigation links

  // }
  // {
  //   id: 'country',
  //   title: 'Country',
  //   icon: <Map size={20} />,
  //   navLink: '/country',
  // },
  // {
  //   id: 'gifting',
  //   title: 'Gifting',
  //   icon: <Gift size={20} />,
  //   navLink: '/gifting',
  // },
  {
    id: 'influencers',
    title: { en: 'Influencers', fr: 'influenceurs' },
    icon: <Users size={20} />,
    navLink: '/influencers',
  },
  {
    id: 'products',
    title: { en: 'Products', fr: 'Produits' },
    icon: <ShoppingBag size={20} />,
    navLink: '/products',
  },
 /* {
    id: 'campagnes',
    title: { en: 'Campaigns', fr: 'Campagnes' },
    icon: <Gift size={20} />,
    navLink: '/campaigns',
  },*/
  {
    id: 'localizations',
    title: { en: 'Localizations', fr: 'Localisations' },
    icon: <Map size={20} />,
    navLink: '/localizations',
  },
  // {
  //   id: 'stats-dashboard',
  //   title: 'Category',
  //   icon: <Box size={20} />,
  //   navLink: '/category',
  // },
]
