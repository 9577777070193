import {createAsyncThunk} from "@reduxjs/toolkit";
import {getUnixTime} from "date-fns";
import {EP_INFLUENCERS_INSIGHTS, EP_INFLUENCERS_PERFORMANCES, EP_INFLUENCERS_PERIOD_SALES} from "../../Api/endPoints";
import {getData} from "../../Api/api";
import {
    chartArrayMapper,
    formatCurrencyCompactEUR,
    formatNumberChart,
    getUnixTimestampUTC
} from "../../utility/Utils";
import {salesChart} from "./influencer.mapper";

export const getInfluencerPerformances = createAsyncThunk(
    "influencer/getPerformances",
    async ({startDate, endDate, brand = 0}, thunkAPI) => {
        try {
            const params = {
                start: getUnixTime(startDate),
                end: getUnixTime(endDate),
                brand,
                timezone: new Date().getTimezoneOffset()
            };

            const {
                average_cart: averageCart,
                average_clicks: averageClicks,
                best_social_media: bestSocialMedia,
                active_influencers_nbr: activeInfluencersNbr,
                best_of_influencers: bestOfInfluencers
            } = await getData(EP_INFLUENCERS_PERFORMANCES, {params});

            const {influencerStatsData} = thunkAPI.getState().influencer;

            return {
                ...influencerStatsData,
                global: {
                    averageCart: averageCart ? formatCurrencyCompactEUR(averageCart) : 0,
                    averageClicks: averageClicks ? formatNumberChart(averageClicks) : 0,
                    bestSocialMedia: bestSocialMedia ?? {total_clicks: 0, socialMedia: ""},
                    activeInfluencersNbr: activeInfluencersNbr ?? 0
                },
                charts: {
                    sales: {unit: '', labels: [], datasets: []},
                    bestOfInfluencers: bestOfInfluencers ? chartArrayMapper(bestOfInfluencers, 'name', 'turnover')
                        : {labels: [], datasets: {data: []}}
                }
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.message);
        }
    }
);

export const getInfluencers = createAsyncThunk(
    "influencer/getInfluencers",
    async ({startDate, endDate, brand = 0}, thunkAPI) => {
        try {
            const params = {
                start: getUnixTime(startDate),
                end: getUnixTime(endDate),
                brand,
                timezone: new Date().getTimezoneOffset()
            };

            const {details_per_influencer} = await getData(EP_INFLUENCERS_INSIGHTS, {params});

            const {influencerStatsData} = thunkAPI.getState().influencer;

            return {
                ...influencerStatsData,
                influencers: details_per_influencer?.filter(value => Object.keys(value).length !== 0).sort((a, b) => b.turnover - a.turnover) || []
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.message);
        }
    }
);

export const getSaleByInfluencerId = createAsyncThunk(
    "influencer/getSaleByInfluencerId",
    async ({startDate, endDate, brand = 0, influencer}, thunkAPI) => {
        try {
            const params = {
                start: getUnixTimestampUTC(startDate),
                end: getUnixTimestampUTC(endDate),
                brand,
                uid: influencer,
                timezone: new Date().getTimezoneOffset()
            }

            const sales = await getData(EP_INFLUENCERS_PERIOD_SALES, {params});

            const {influencerStatsData} = thunkAPI.getState().influencer;

            return {
                ...influencerStatsData,
                charts: {
                    ...influencerStatsData?.charts,
                    sales: salesChart(sales, sales?.[0]?.type || '', startDate, influencerStatsData?.influencers)
                },
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.message);
        }
    }
);
