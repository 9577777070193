// ** React Imports
import { Link } from "react-router-dom";

// ** Custom Components
import Avatar from "@components/avatar";

// ** Third Party Components
import { Power } from "react-feather";

// ** Reactstrap Imports
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

// ** Default Avatar Image
import { useAuth } from "../../../auth/firebase/useAuth.js";

const UserDropdown = () => {
 const { logoutUser, user } = useAuth();

 const signOut = () => {
  logoutUser();
 };
 // FIXME: fix name and role user
 return (
  <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
   {user && (
    <DropdownToggle
     href="/"
     tag="a"
     className="nav-link dropdown-user-link"
     onClick={(e) => e.preventDefault()}
    >
     <div className="user-nav d-sm-flex d-none">
      <span className="user-name fw-bold">
       {user?.brand?.displayName ? user?.brand?.displayName : user?.email.split("@")[0]}
      </span>
      <span className="user-status">{user?.brand?.role ? user?.brand?.role : "Admin"}</span>
     </div>
     <Avatar img={user?.brand?.pic} imgHeight="40" imgWidth="40" status="online" />
    </DropdownToggle>
   )}
   <DropdownMenu end>
    {/* <DropdownItem tag={Link} to='/' onClick={(e) => e.preventDefault()}>
          <User size={14} className='me-75' />
          <span className='align-middle'>Profile</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/' onClick={(e) => e.preventDefault()}>
          <Mail size={14} className='me-75' />
          <span className='align-middle'>Inbox</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/' onClick={(e) => e.preventDefault()}>
          <CheckSquare size={14} className='me-75' />
          <span className='align-middle'>Tasks</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/' onClick={(e) => e.preventDefault()}>
          <MessageSquare size={14} className='me-75' />
          <span className='align-middle'>Chats</span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={Link} to='/pages/' onClick={(e) => e.preventDefault()}>
          <Settings size={14} className='me-75' />
          <span className='align-middle'>Settings</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/' onClick={(e) => e.preventDefault()}>
          <CreditCard size={14} className='me-75' />
          <span className='align-middle'>Pricing</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/' onClick={(e) => e.preventDefault()}>
          <HelpCircle size={14} className='me-75' />
          <span className='align-middle'>FAQ</span>
        </DropdownItem> */}
    <DropdownItem tag={Link} to="/login" onClick={signOut}>
     <Power size={14} className="me-75" />
     <span className="align-middle">Logout</span>
    </DropdownItem>
   </DropdownMenu>
  </UncontrolledDropdown>
 );
};

export default UserDropdown;
