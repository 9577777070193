import {createAsyncThunk} from "@reduxjs/toolkit";
import {getUnixTime} from "date-fns";
import {getData} from "../../Api/api";
import {EP_PRODUCTS_PERFORMANCES, EP_PRODUCTS_STATS} from "../../Api/endPoints";
import {chartArrayMapper} from "../../utility/Utils";
import {mappedProductDetail} from "./product.mapper";

export const getProductStats = createAsyncThunk(
    "product/getProductStats",
    async ({startDate, endDate, brand = 0, language = null}, thunkAPI) => {
        try {
            const params = {
                start: getUnixTime(startDate),
                end: getUnixTime(endDate),
                brand,
                // count: 20,
                timezone: new Date().getTimezoneOffset()
            };

            const {
                shared_clicked_products: sharedClickedProducts,
                products_sold: productsSold,
                products_sold_per_subcategories: productsSoldPerSubcategories,
                products_sold_per_colors: productsSoldPerColors,
                products_sold_per_period: productsSoldPerPeriod,
            } = await getData(EP_PRODUCTS_STATS, {params});

            const {productStatsData} = thunkAPI.getState().product;

            const paginatedProductsSoldPerColors = productsSoldPerColors?.sort((a, b) => b.percent - a.percent).slice(0, 6) ?? [];// get last 6 element order by descending percent
            let paginatedProductsSoldPerSubcategories = productsSoldPerSubcategories?.sort((a, b) => b.percent - a.percent).slice(-6) || [];

            return {
                ...productStatsData,
                charts: {
                    ...productStatsData.charts,
                    saleWithColorDetail: chartArrayMapper(paginatedProductsSoldPerColors, `name_${language}`, 'percent', '', '', 'color', 'turnover'),
                    categoriesPercent: chartArrayMapper(paginatedProductsSoldPerSubcategories, `text-${language}`, 'percent', '', ''),
                    categoriesTotalSales: chartArrayMapper(paginatedProductsSoldPerSubcategories, `text-${language}`, 'turnover', '', ''),
                    productDetail: mappedProductDetail(productsSoldPerPeriod, productsSoldPerPeriod?.[0]?.type ?? '', startDate)
                },
                tablesData: {
                    selectedProductTableData: sharedClickedProducts,
                    saledProductTableData: productsSold
                }
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.message);
        }
    }
);

export const getProductPerformances = createAsyncThunk(
    "product/getProductPerformances",
    async ({startDate, endDate, brand = 0}, thunkAPI) => {
        try {
            const params = {
                start: getUnixTime(startDate),
                end: getUnixTime(endDate),
                brand,
                // count: 20,
                timezone: new Date().getTimezoneOffset()
            };

            const {
                shared_products_clicks: sharedProductsClicks,
                products_sold: productsSold
            } = await getData(EP_PRODUCTS_PERFORMANCES, {params});

            return {
                global: {
                    sharedProductsClicks: sharedProductsClicks ? sharedProductsClicks : 0,
                    productsSold: productsSold ? productsSold : 0,
                    sharedProductsClicksNotSolded: sharedProductsClicks && productsSold ? Math.abs(sharedProductsClicks - productsSold) : 0
                }
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.message);
        }
    }
);
